<template>
  <nav-menu></nav-menu>
  <div class="content">
      <router-view>  </router-view>
  </div>
  <Footer></Footer>    
</template>

<script>
    import NavMenu from './components/NavMenu.vue'
    import Footer from './components/Footer.vue'
    import focus from './directives/focus'

export default {
  name: 'App',
  components: {
      NavMenu,
      Footer
        }
}
</script>

<style>
/*#app {
 
}
#app .content{
    padding-top:100px;
}*/
</style>
